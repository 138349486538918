import type { Store } from '@/data';
import { DocumentData, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase';

export const getStore = async (storeId: string): Promise<Store | undefined> => {
  try {
    const docRef = doc(db, 'stores', storeId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    const id = docSnap.id;
    if (!data) {
      console.warn('Retrieved empty data for store:', id);
      return;
    }

    return mapToStore(id, data);
  } catch (error) {
    console.error('Error fetching data:', error);
    return;
  }
};

export const getStoreDashboardUrl = async (storeId: string, dashboardId: string): Promise<string | undefined> => {
  try {
    const docRef = doc(db, 'stores', storeId, 'dashboards', dashboardId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const id = docSnap.id;
    if (!data) {
      console.warn('Retrieved empty data for dashboard:', id);
      return;
    }

    return data.url && data.url.length > 0 ? data.url : undefined;
  } catch (error) {
    console.error('Error fetching data:', error);
    return;
  }
};

export const fetchStore = (
  storeId: string,
  onDataUpdate: (store: Store | null) => void,
  onError: (error: unknown) => void,
) => {
  const docRef = doc(db, 'stores', storeId);

  return onSnapshot(
    docRef,
    async (docSnap) => {
      try {
        const data = docSnap.exists() ? docSnap.data() : undefined;

        if (data) {
          const storeData = mapToStore(storeId, data);

          onDataUpdate(storeData);
        } else {
          onDataUpdate(null);
        }
      } catch (error) {
        onError(error);
      }
    },
    onError,
  );
};

export const mapToStore = (id: string, data: DocumentData): Store => {
  return {
    id: id,
    name: data.name,
    owner: data.owner,
    chain: data.chain,
    personOfContact: data.person_of_contact,
  };
};
