import { FirestoreError, collection, onSnapshot } from 'firebase/firestore';

import type { Dashboard } from '@/data';
import { db } from './firebase';

export const fetchDashboards = (
  storeId: string,
  onDataUpdate: (dashboards: Dashboard[]) => void,
  onError: (error: FirestoreError) => void,
) => {
  const docRef = collection(db, `stores/${storeId}/dashboards`);

  return onSnapshot(
    docRef,
    (querySnapshot) => {
      const dashboardData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<Dashboard, 'id'>),
      }));
      onDataUpdate(dashboardData);
    },
    onError,
  );
};
